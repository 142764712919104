<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="small" :spinning="spinnerLoader">
			<a-form layout="vertical" :form="form" @submit="handleSubmit">
				<div class="row">
					<div class="col-md-4">
						<a-form-item label="Nombre(s)">
							<a-input
								v-decorator="[
									'name',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]"
								autocomplete="off"
							/>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item label="Apellido Paterno">
							<a-input
								v-decorator="[
									'first_lastname',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]"
							/>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item label="Apellido Materno">
							<a-input v-decorator="['second_lastname']" autocomplete="off" />
						</a-form-item>
					</div>
				</div>
				<div class="row">
					<div class="col-md-3">
						<a-form-item label="Nombre clave">
							<a-input
								v-decorator="[
									'username',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]"
								autocomplete="off"
							/>
						</a-form-item>
					</div>
					<div class="col-md-3">
						<a-form-item label="Correo">
							<a-input
								v-decorator="[
									'email',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]"
							/>
						</a-form-item>
					</div>
					<div class="col-md-3">
						<a-form-item label="Teléfono celular">
							<a-input
								v-decorator="[
									'mobile_number',
									{
										rules: [
											{
												required: true,
												message: 'Campo requerido.',
											},
										],
									},
								]"
								autocomplete="off"
								v-mask="'##########'"
							/>
						</a-form-item>
					</div>
					<div class="col-md-3">
						<a-form-item label="Area">
							<a-select v-decorator="['areas']">
								<a-select-option v-for="(area, index) in businessAreasList" :key="index" :value="area.id">{{ area.name }}</a-select-option>
							</a-select>
						</a-form-item>
					</div>
				</div>
				<div class="row">
					<div class="col-md-4">
						<a-form-item label="Contraseña">
							<a-input-password
								v-decorator="[
									'password',
									{
										rules: [
											{
												min: 6,
												message: 'Ingrese contraseña de al menos 6 caracteres.',
											},
											{
												validator: validateToNextPassword,
											},
										],
									},
								]"
							/>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<a-form-item label="Confirmar contraseña">
							<a-input-password
								@blur="handleConfirmBlur"
								v-decorator="[
									'confirmPassword',
									{
										rules: [
											{
												min: 6,
												message: 'Ingrese contraseña de al menos 6 caracteres.',
											},
											{
												validator: compareToFirstPassword,
											},
										],
									},
								]"
								autocomplete="off"
							/>
						</a-form-item>
					</div>
					<div class="col-md-4">
						<div class="btnPasswordGenerator">
							<a-button icon="lock" class="mr-1 btn btn-info btn-block" @click="onGeneratePassword">Generar contraseña</a-button>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="col-md-4 text-left">
						<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">Regresar</a-button>
					</div>
					<div class="col-md-8 text-right">
						<a-button icon="save" class="btn btn-success" htmlType="submit">Guardar</a-button>
					</div>
				</div>
			</a-form>
		</a-spin>
	</div>
</template>
<script>
//
import { mapGetters } from 'vuex'
import utilities from '@/services/utilities'
import _ from 'lodash'
import { mask } from 'vue-the-mask'

export default {
	name: 'insertOrUpdateUsers',
	directives: {
		mask,
	},

	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('users', ['usersList']),
		...mapGetters('businessAreas', ['businessAreasList']),

		spinnerLoaderLabel() {
			return this.$store.state.users.spinnerLoaderLabel
		},
		spinnerLoader() {
			return this.$store.state.users.spinnerLoader
		},
	},
	data() {
		return {
			form: this.$form.createForm(this),
			submitLabel: '',
		}
	},
	mounted() {
		if (this.businessAreasList.length == 0) {
			this.$store.dispatch('businessAreas/GET')
		}
	},
	methods: {
		onCancel() {
			this.$router.replace('/system/users')
		},
		onGeneratePassword() {
			utilities.passwordGenerator().then((passwd) => {
				this.form.setFieldsValue({ password: passwd, confirmPassword: passwd })
			})
		},
		handleConfirmBlur(e) {
			const value = e.target.value
			this.confirmDirty = this.confirmDirty || !!value
		},
		compareToFirstPassword(rule, value, callback) {
			const form = this.personalInformationForm
			if (value && value !== form.getFieldValue('password')) {
				callback('Las contraseñas deben coincidir.')
			} else {
				callback()
			}
		},
		validateToNextPassword(rule, value, callback) {
			const form = this.personalInformationForm
			if (value && this.confirmDirty) {
				form.validateFields(['confirmPassword'], { force: true })
			}
			callback()
		},
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields((err, values) => {
				if (!err) {
					let payload = {
						...values,
					}
					this.$store.dispatch('users/CREATE', payload)
				}
			})
		},
	},
}
</script>
<style lang="scss" module>
@import './style.module.scss';
</style>